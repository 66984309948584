
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "electronic-signature",
  components: {},
  emits: ["updatedSignature"],
  props: {
    id: {
      type: String,
      default: "sign-modal",
    },
    signatureType: {
      type: String,
      default: "update",
    },
    requireReason: {
      type: Boolean,
      default: true,
    },
    requireExtra: {
      type: Boolean,
      default: true,
    },
    reasonList: {
      type: Array,
      default: () => {
        return ["Cambio de domicilio", "Edición de Datos Personales", "Otros"];
      },
    },
  },
  computed: {
    passwordFieldType() {
      if (this.passwordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
    canSendForm() {
      return false;
    },
  },
  methods: {
    clear() {
      this.formData = {
        type: "",
        reason: "",
        username: "",
        password: "",
        extra: "",
      };
    },
    handleEnter(key) {
      if (key.code == "Enter") {
        this.signRequest();
      }
    },
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
    signRequest() {
      this.formData.type = this.signatureType;
      ApiService.post("sign", this.formData)
        .then((response) => {
          this.signature = response.data.signature;
          let myModal = document.getElementById(this.id);
          if (myModal) {
            myModal.click();
          }
          this.$emit("updatedSignature", { signature: this.signature });
          this.clear();
        })
        .catch((error) => {
          this.clear();
          Swal.fire({
            text: error.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Cerrar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

          this.errors = error.response.data.errors;
        });
    },
  },
  setup() {
    const donorCheckModal = ref<null | HTMLElement>(null);
    return { donorCheckModal };
  },
  data: () => {
    return {
      passwordVisible: false,
      errors: {},
      loading: false,
      formData: {
        type: "",
        reason: "",
        username: "",
        password: "",
        extra: "",
      },
      signature: "",
    };
  },
});
