
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "NewClaim-modal",
  components: { ElectronicSignature },
  emits: ["checkregistered"],
  props: {
    donorType: String,
    donorId: Number,
  },
  computed: {
    canSendForm() {
      return this.claimReason === "";
    },
  },
  methods: {
    triggerSignatureForm() {
      let modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },

    catchErrors(error) {
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    storeFormDataWithSignature(signature) {
      ApiService.post("claims", {
        donor_id: this.donorId,
        reason_id: this.claimReason,
        details: this.observations,
        electronicSignature: signature,
      })
        .then(() => {
          let myModal = document.getElementById("NewClaim-modal");
          if (myModal) {
            myModal.click();
          }
          this.$emit("checkregistered");
        })
        .catch(this.catchErrors);
    },
    storeCheck() {
      ApiService.post("claims", {
        donor_id: this.donorId,
        reason_ids: this.claimReason,
        details: this.observations,
      })
        .then(() => {
          let myModal = document.getElementById("NewClaim-modal");
          if (myModal) {
            myModal.click();
          }
          this.$emit("checkregistered");
        })
        .catch(this.catchErrors);
    },
  },
  mounted() {
    ApiService.query("claims/reasons", {
      params: { donorType: this.donorType },
    }).then((res) => {
      this.reasonList = res.data;
    });
  },
  setup() {
    const donorCheckModal = ref<null | HTMLElement>(null);
    return { donorCheckModal };
  },
  data: () => {
    return {
      loading: false,
      reasonList: [],
      claimReason: "",
      observations: "",
      errors: {},
    };
  },
});
