
import { defineComponent, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "entitytable",
  template: "#entitytable",
  components: {},
  props: {
    filterData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    resourceName: {
      type: String,
      required: true,
    },
    columnsData: {
      type: Array,
      required: true,
    },
    routePrefix: {
      type: String,
      required: true,
    },
    permissionPrefix: {
      type: String,
      required: false,
      default: "",
    },
    showQuickfilter: Boolean,
    showCreate: Boolean,
    showExport: Boolean,
    showEdit: Boolean,
    showHeader: Boolean,
    showActions: Boolean,
    showPagination: Boolean,
    entityName: String,
    widgetClasses: String,
  },

  data() {
    return {
      filters: {
        string: "",
      },
      perPage: 10,
      exportingData: "",
      entities: null,
      stringFilter: "",
      page: 1,
      pages: [],
      count: 0,
      roles: {
        transport_manager: {
          name: "Transportista",
          icon: "media/icons/duotone/Shopping/Box3.svg",
        },
        transport_visitor: {
          name: "Visitadora",
          icon: "media/icons/duotone/Clothes/Shirt.svg",
        },
      },
    };
  },
  methods: {
    exportData() {
      this.exportingData = "on";
      ApiService.query("export/" + this.resourceName, {
        responseType: "blob",
        params: {
          filters: this.filterData,
          filterString: this.filters.string,
        },
      }).then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export.pdf";
        link.click();
        this.exportingData = "off";
      });
    },
    getStatusClass(status) {
      switch (status) {
        case "active":
          return "svg-icon-success";
        case "inactive":
          return "svg-icon-danger";
      }
    },
    triggerSearch(instant) {
      setTimeout(
        () => {
          this.page = 1;
          this.filters.string = this.stringFilter;
          this.retrieveData();
        },
        instant ? 10 : 400
      );
    },
    gotoPage(pageNumber) {
      this.page = pageNumber;
      this.retrieveData();
    },

    retrieveData() {
      ApiService.query(this.resourceName, {
        params: {
          perPage: this.perPage,
          page: this.page,
          filters: this.filterData,
          filterString: this.filters.string,
        },
      }).then((response) => {
        this.entities = response.data.data;
        this.count = response.data.total;

        setTimeout(() => {
          MenuComponent.reinitialization();
        }, 300);
      });
    },
  },
  mounted() {
    this.retrieveData();
  },
  setup() {
    const store = useStore();
    return { store };
  },
});
