
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";
import moment from "moment/moment";

export default defineComponent({
  name: "SuspendDonation-modal",
  components: { ElectronicSignature },
  emits: ["afterSuspension"],
  props: {
    donorType: String,
    donorId: Number,
  },
  computed: {
    canSendForm() {
      return (
        (this.suspendReason == "Otros" && this.observations === "") ||
        this.suspendReason === ""
      );
    },
  },
  methods: {
    checkDate(date) {
      let m = moment(date);
      return !m.isAfter(moment(), "day");
    },
    triggerSignatureForm() {
      let modalito = new Modal(
        document.getElementById("suspendDonationSignatureModal")
      );
      modalito.show();
    },

    catchErrors(error) {
      if (error.response.status == 451) {
        this.closeModal();
        return this.triggerSignatureForm();
      }

      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    closeModal() {
      let myModal = document.getElementById("SuspendDonation-modal");
      if (myModal) {
        myModal.click();
      }
    },
    suspendWithSignature(signature) {
      ApiService.post("donors/" + this.donorId + "/suspension", {
        donor_id: this.donorId,
        until: this.suspendUntil,
        pickup: !this.dontPickup,
        reason: this.suspendReason,
        details: this.observations,
        electronicSignature: signature,
      })
        .then(() => {
          this.closeModal();
          this.$emit("afterSuspension");
        })
        .catch(this.catchErrors);
    },
    storeSuspend() {
      ApiService.post("donors/" + this.donorId + "/suspension", {
        donor_id: this.donorId,
        until: this.suspendUntil,
        pickup: !this.dontPickup,
        reason: this.suspendReason,
        details: this.observations,
      })
        .then(() => {
          this.$emit("afterSuspension");
        })
        .catch(this.catchErrors);
    },
  },

  setup() {
    const suspendDonationModal = ref<null | HTMLElement>(null);
    return { suspendDonationModal };
  },
  data: () => {
    return {
      loading: false,
      suspendUntil: "",
      dontResume: false,
      reasonList: { 0: "No se encontrará en su domicilio", 1: "Otros" },
      suspendReason: "",
      dontPickup: true,
      observations: "",
      errors: {},
    };
  },
});
